import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Form from "./components/Form";
import Admin from "./components/Admin";
import "./index.css";

function App() {
  return (
    <Router>
      <div className="flex flex-col items-center p-8 min-h-screen bg-gray-100">
        <h1 className="text-2xl font-bold text-center mb-8">
          Formulaire d'Onboarding IT
        </h1>
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
