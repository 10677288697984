import React, { useState, useEffect } from "react";
import "../styles/Admin.css";

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch("https://api.formdulibre.ch/get-users");
        const data = await response.json();

        if (data.success) {
          setUsers(data.data);
        } else {
          setError(
            data.message || "Erreur lors de la récupération des utilisateurs",
          );
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Erreur réseau : Impossible de récupérer les utilisateurs");
      }
    };

    fetchUsers();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatType = (type) => {
    const typeMap = {
      LearningLabJeune: "Jeune - Explore",
    };
    return typeMap[type] || type;
  };

  const handleDeactivate = (userId) => {
    console.log(`Désactiver user with ID: ${userId}`);
  };

  return (
    <div className="admin-container">
      <h1 className="admin-title">Page Admin - Liste des Utilisateurs</h1>
      {error && <p className="error-message">{error}</p>}
      <table className="admin-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th>Date d’entrée</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.nom}</td>
              <td>{user.prenom}</td>
              <td>{user.email}</td>
              <td>{formatDate(user.date_entree)}</td>
              <td>{formatType(user.type)}</td>
              <td>
                <button
                  onClick={() => handleDeactivate(user.id)}
                  className="deactivate-button"
                >
                  Désactiver
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminPage;
