import React, { useState, useEffect } from "react";
import "../styles/layout.css";
import "../styles/formStyles.css";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTransition, animated } from "@react-spring/web";
import formValidationSchema from "../validation/formValidationSchema";
import PersonalInfoSection from "./PersonalInfoSection";
import EquipmentSection from "./EquipmentSection";
import GroupSettingsSection from "./GroupSettingsSection";
import OtherApplicationsSection from "./OtherApplicationsSection";
import NavigationButtons from "./NavigationButtons";
import SuccessPage from "./SuccessPage";

function Form() {
  const methods = useForm({
    resolver: yupResolver(formValidationSchema),
    mode: "all",
  });
  const {
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = methods;

  const type = watch("type");
  const prenom = watch("prenom");
  const nom = watch("nom");
  const isReadOnly = type === "LearningLabJeune";
  const [page, setPage] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    if (isReadOnly) {
      setValue("mattermostLearningLab", true);
      setValue("tipee", true);
    }
  }, [isReadOnly, setValue]);

  const displayedSections = [
    <PersonalInfoSection isReadOnly={isReadOnly} />,
    <EquipmentSection isReadOnly={isReadOnly} />,
    <GroupSettingsSection isReadOnly={isReadOnly} />,
    <OtherApplicationsSection isReadOnly={isReadOnly} />,
  ];

  const transition = useTransition(isSubmitted ? "success" : page, {
    key: isSubmitted ? "success" : page,
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(-100%)" },
  });

  const handleNext = () => {
    if (isReadOnly && page === displayedSections.length - 1) {
      handleSubmit(onSubmit)();
    } else {
      setPage((prevPage) => Math.min(prevPage + 1, displayedSections.length - 1));
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://api.formdulibre.ch/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.success) {
        setIsSubmitted(true);
        setStatusMessage("");
      } else {
        setStatusMessage("Erreur durant l'envoi du mail");
      }
    } catch (error) {
      setStatusMessage("Erreur réseau, envoi du mail impossible");
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="page-wrapper">
        <animated.div className="sidebar">
          <div className="sidebar-logo-wrapper">
            <img
              src="https://for-pro.ch/logo.svg"
              alt="ForPro Logo"
              className="sidebar-logo"
            />
          </div>
          <div className="sidebar-content">
            <p className="sidebar-field">
              <strong>Prénom :</strong> {prenom || "Non renseigné"}
            </p>
            <p className="sidebar-field">
              <strong>Nom :</strong> {nom || "Non renseigné"}
            </p>
          </div>
        </animated.div>
        <div className="main-content">
          <div className="form-content">
            <form onSubmit={handleSubmit(onSubmit)} className="form-container">
              {transition((style, item) => (
                <animated.div style={style} className="form-section">
                  {item === "success" ? (
                    <SuccessPage message={statusMessage} />
                  ) : (
                    displayedSections[item]
                  )}
                </animated.div>
              ))}
              {!isSubmitted && (
                <>
                  <NavigationButtons
                    page={page}
                    setPage={setPage}
                    maxPage={displayedSections.length - 1}
                    handleNext={handleNext}
                    handlePrevious={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    isLastPage={page === displayedSections.length - 1}
                    isLearningLabJeune={isReadOnly}
                    isFormValid={isValid}
                  />
                  {page === displayedSections.length - 1 && !isReadOnly && (
                    <button type="submit" className="submit-button">
                      Soumettre
                    </button>
                  )}
                </>
              )}
              {statusMessage && <p className="status-message">{statusMessage}</p>}
            </form>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default Form;
