import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

function OtherApplicationsSection() {
  const { register, setValue, watch, control } = useFormContext();
  const type = useWatch({ control, name: "type" });
  const telephone = useWatch({ control, name: "telephone" });

  const isLearningLabJeune = type === "LearningLabJeune";
  const allFields = watch([
    "canvas",
    "chatGPT",
    "miro",
    "notion",
    "tipee",
    "wondershare",
    isLearningLabJeune ? "3cx" : "topboard", 
  ]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setValue("tipee", true);
    if (isLearningLabJeune) {
      setValue("topboard", false); 
    }
  }, [isLearningLabJeune, setValue]);

  useEffect(() => {
    if (telephone) {
      setValue("3cx", true); 
    }
  }, [telephone, setValue]);

  useEffect(() => {
    setAllSelected(allFields.every(Boolean));
  }, [allFields]);

  const toggleSelectAll = () => {
    const newValue = !allSelected;
    setValue("canvas", newValue);
    setValue("chatGPT", newValue);
    setValue("miro", newValue);
    setValue("notion", newValue);
    setValue("tipee", newValue);
    setValue("wondershare", newValue);
    if (!isLearningLabJeune) {
      setValue("topboard", newValue);
    }
  };

  return (
    <section className="flex flex-col gap-4">
      <h2 className="text-xl font-semibold">Autres Applications</h2>
      {!isLearningLabJeune && (
        <button
          type="button"
          onClick={toggleSelectAll}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          {allSelected ? "Tout Désélectionner" : "Tout Sélectionner"}
        </button>
      )}
      <label>
        <input
          {...register("canvas")}
          type="checkbox"
          disabled={isLearningLabJeune}
        />{" "}
        Canvas
      </label>
      <label>
        <input
          {...register("chatGPT")}
          type="checkbox"
          disabled={isLearningLabJeune}
        />{" "}
        ChatGPT
      </label>
      <label>
        <input
          {...register("miro")}
          type="checkbox"
          disabled={isLearningLabJeune}
        />{" "}
        Miro
      </label>
      <label>
        <input
          {...register("notion")}
          type="checkbox"
          disabled={isLearningLabJeune}
        />{" "}
        Notion
      </label>
      <label>
        <input
          {...register("tipee")}
          type="checkbox"
          disabled={isLearningLabJeune}
        />{" "}
        Tipee
      </label>
      {!isLearningLabJeune && (
        <label>
          <input {...register("topboard")} type="checkbox" /> Topboard
        </label>
      )}
      {isLearningLabJeune && (
        <label>
          <input {...register("3cx")} type="checkbox" disabled /> 3CX
        </label>
      )}
      <label className="flex flex-col gap-2">
        <span>Remarques</span>
        <textarea
          {...register("remarque")}
          className="border rounded p-3 text-lg w-full resize-none"
          rows="4"
        />
      </label>
    </section>
  );
}

export default OtherApplicationsSection;
