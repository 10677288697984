import * as yup from "yup";

const formValidationSchema = yup.object().shape({
  nom: yup.string().required("Le nom est requis"),
  prenom: yup.string().required("Le prénom est requis"),
  email: yup.string().email("Email invalide").required("Email est requis"),
  dateEntree: yup.date().required("La date d’entrée est requise"),
});

export default formValidationSchema;
