import React from "react";

function NavigationButtons({
  page,
  maxPage,
  handleNext,
  handlePrevious,
  isLastPage,
  isLearningLabJeune,
  isFormValid,
}) {
  return (
    <div className="flex justify-between mt-4">
      {page > 0 && (
        <button
          type="button"
          onClick={() => handlePrevious && handlePrevious()}
          className="px-4 py-2 bg-gray-200 rounded"
        >
          Précédent
        </button>
      )}
      {(!isLastPage || (isLastPage && isLearningLabJeune)) && (
        <button
          type="button"
          onClick={isFormValid ? handleNext : undefined} // Disable if form is invalid
          className={`px-4 py-2 rounded ${isFormValid ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-500"}`}
          disabled={!isFormValid}
        >
          {isLastPage && isLearningLabJeune ? "Soumettre" : "Suivant"}
        </button>
      )}
    </div>
  );
}

export default NavigationButtons;
