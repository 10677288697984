import React from "react";

function SuccessPage() {
  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold text-green-600 mb-4">
        Formulaire soumis avec succès!
      </h2>
      <p className="text-gray-700">
        {"Votre formulaire a été soumis avec succès et un e-mail a été envoyé!"}
      </p>
    </div>
  );
}

export default SuccessPage;
