import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

function GroupSettingsSection() {
  const { register, setValue, control } = useFormContext();
  const type = useWatch({ control, name: "type" });

  const isReadOnly = type === "LearningLabJeune";

  useEffect(() => {
    const isForProOrApprenti =
      type === "ForPro Team" || type === "Apprenti" || type === "Stagiaire";
    const isLearningLabReferent = type === "LearningLabReferent";
    const isLearningLabJeune = type === "LearningLabJeune";

    if (isForProOrApprenti || isLearningLabReferent) {
      setValue("calendrier", true);
      setValue("hidProtectas", true);
      setValue("imprimantes", true);
      setValue("forPro", true);
      setValue("mattermostForPro", true);
      setValue("nasForPro", true);
    }

    if (isLearningLabReferent) {
      setValue("explore", true);
      setValue("mattermostLearningLab", true);
      setValue("nasExplore", true);
      setValue("nasForPro", true); 
    }

    if (isLearningLabJeune) {
      setValue("mattermostLearningLab", true);
      setValue("nasExplore", true); 
    }
  }, [type, setValue]);

  // Capitalize function to format group names
  const capitalize = (text) => {
    return text.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()).trim();
  };

  return (
    <section className="p-6 bg-white rounded-lg shadow-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Groupes et Accès</h2>
      
      {/* General Access Section */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("hidProtectas")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          HID Protectas
        </label>
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("imprimantes")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          Imprimantes et scanners
        </label>
      </div>

      {/* Nextcloud Section avec noms capitalisés */}
      <h3 className="text-lg font-semibold text-blue-600 mb-4">Groupes dans Nextcloud</h3>
      <div className="grid grid-cols-3 gap-4 mb-6">
        {[
          "forPro", "foodLab", "learningLab", "explore", "responsablesLabs", 
          "apprentis", "conduiteStrategique", "administration", "gestionFinances", 
          "codir", "stagiaires", "supportIT", "marketing", "direction", 
          "servicesGeneraux", "production", "qualite", "ressourcesHumaines"
        ].map((group) => (
          <label
            key={group}
            className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100 break-words"
          >
            <input
              {...register(group)}
              type="checkbox"
              disabled={isReadOnly}
              className="text-blue-600 focus:ring-blue-500"
            />
            <span className="whitespace-normal">{capitalize(group)}</span>
          </label>
        ))}
      </div>

      {/* Mattermost Section */}
      <h3 className="text-lg font-semibold text-blue-600 mb-4">Groupe Mattermost</h3>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("mattermostForPro")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          ForPro
        </label>
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("mattermostLearningLab")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          LearningLab
        </label>
      </div>

      {/* Synology Section */}
      <h3 className="text-lg font-semibold text-blue-600 mb-4">Synology</h3>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("nasForPro")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          ForPro
        </label>
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("nasExplore")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          Explore
        </label>
      </div>

      {/* Groupe Office Section */}
      <h3 className="text-lg font-semibold text-blue-600 mb-4">Groupe Office</h3>
      <div className="flex items-center gap-2 mb-4">
        <input
          {...register("calendrier")}
          type="checkbox"
          disabled={isReadOnly}
          className="text-blue-600 focus:ring-blue-500"
        />
        <span>Calendrier</span>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="flex flex-col col-span-2">
          <label className="text-sm text-gray-600 mb-1" htmlFor="accessCalendrier">
            Accès au calendrier de:
          </label>
          <textarea
            {...register("accessCalendrier")}
            id="accessCalendrier"
            placeholder="Nom du calendrier"
            className="border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 w-full h-24 resize-none"
            disabled={isReadOnly}
          />
        </div>
        <div className="flex flex-col col-span-2">
          <label className="text-sm text-gray-600 mb-1" htmlFor="boiteAuxLettres">
            Boîte aux lettres:
          </label>
          <textarea
            {...register("boiteAuxLettres")}
            id="boiteAuxLettres"
            placeholder="Adresse de la boîte"
            className="border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 w-full h-24 resize-none"
            disabled={isReadOnly}
          />
        </div>
      </div>

      {/* Requea Section */}
      <h3 className="text-lg font-semibold text-blue-600 mb-4">Requea</h3>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("requeaAdmin")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          Administrateur
        </label>
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("requeaValidateurs")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          Validateur
        </label>
        <label className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100">
          <input
            {...register("requeaDemandeur")}
            type="checkbox"
            disabled={isReadOnly}
            className="text-blue-600 focus:ring-blue-500"
          /> 
          Demandeur
        </label>
      </div>
    </section>
  );
}

export default GroupSettingsSection;
