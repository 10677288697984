import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import "./EquipmentSection.css"; // Fichier CSS pour animations

function EquipmentSection() {
  const { register, watch, setValue, control } = useFormContext();
  const ordinateurFixe = watch("ordinateurFixe");
  const ordinateurPortable = watch("ordinateurPortable");
  const userType = useWatch({ control, name: "type" });

  const isJeune = userType === "LearningLabJeune";

  useEffect(() => {
    // Ordinateur portable activé par défaut pour les utilisateurs non-jeunes
    if (!isJeune) {
      setValue("ordinateurPortable", true);
    }
  }, [isJeune, setValue]);

  const handleSwitch = (type) => {
    if (type === "fixe") {
      setValue("ordinateurFixe", true);
      setValue("ordinateurPortable", false);
      setValue("docking", false); // Docking décoché si fixe sélectionné
    } else if (type === "portable") {
      setValue("ordinateurPortable", true);
      setValue("ordinateurFixe", false);
    }
  };

  return (
    <section className="flex flex-col gap-4">
      <h2 className="text-xl font-semibold">Matériel Nécessaire</h2>

      {/* Ordinateur fixe */}
      <label className={`switch ${ordinateurFixe ? "active" : ""}`}>
        <input
          type="checkbox"
          checked={ordinateurFixe}
          disabled={isJeune}
          onChange={() => handleSwitch("fixe")}
        />
        Ordinateur fixe
      </label>

      {/* Ordinateur portable */}
      <label className={`switch ${ordinateurPortable ? "active" : ""}`}>
        <input
          type="checkbox"
          checked={ordinateurPortable}
          disabled={isJeune}
          onChange={() => handleSwitch("portable")}
        />
        Ordinateur portable
      </label>

      {/* Docking */}
      <label className={`switch ${ordinateurFixe ? "disabled" : ""}`}>
        <input
          {...register("docking")}
          type="checkbox"
          disabled={isJeune || ordinateurFixe}
        />
        Docking
      </label>

      {/* Écran supplémentaire */}
      <label className="switch">
        <input
          {...register("ecranSupp")}
          type="checkbox"
          disabled={isJeune}
        />
        Écran supplémentaire
      </label>

      {/* Clavier/souris supplémentaire */}
      <label className="switch">
        <input
          {...register("clavierSourisSupp")}
          type="checkbox"
          disabled={isJeune}
        />
        Clavier/souris supplémentaire
      </label>

      {/* Téléphone portable */}
      <label className="switch">
        <input
          {...register("telephone")}
          type="checkbox"
          disabled={isJeune}
        />
        Téléphone portable
      </label>

      {/* Autre */}
      <label>
        Autre:
        <input
          {...register("autre")}
          type="text"
          disabled={isJeune}
          className="ml-2 px-2 py-1 border rounded"
        />
      </label>
    </section>
  );
}

export default EquipmentSection;
