import React, { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import config from "./conf.json";
import "./ToggleAnimation.css";

function PersonalInfoSection() {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const [selectedLab, setSelectedLab] = useState("ForPro");

  const nom = useWatch({ control, name: "nom" });
  const prenom = useWatch({ control, name: "prenom" });
  const type = useWatch({ control, name: "type" });
  const compteUtilisateur = useWatch({ control, name: "compteUtilisateur" });

  useEffect(() => {
    const cleanName = (name) => {
      const parts = name.trim().toLowerCase().split(" ");
      if (parts[0].length <= 3 && parts.length > 1) {
        return parts[0] + parts[1];
      }
      return parts[0];
    };

    if (prenom && nom) {
      const parsedNom = cleanName(nom);
      const parsedPrenom = prenom.split(" ")[0].toLowerCase();
      const username = `${parsedPrenom}.${parsedNom}`;
      setValue("compteUtilisateur", username);
    }
  }, [prenom, nom, setValue]);

  useEffect(() => {
    if (compteUtilisateur) {
      setValue("email", `${compteUtilisateur}@for-pro.ch`);
    }
  }, [compteUtilisateur, setValue]);

  useEffect(() => {
    if (selectedLab === "ForPro") {
      setValue("type", "ForPro Team");
    } else if (selectedLab === "LearningLab") {
      setValue("type", "LearningLabReferent");
    }
  }, [selectedLab, setValue]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setValue("dateEntree", today);
  }, [setValue]);

  const getTypeOptions = () => {
    if (selectedLab === "LearningLab") {
      return [
        { value: "LearningLabJeune", label: "Jeune - Explore" },
        { value: "LearningLabReferent", label: "LearningLab - Staff" }
      ];
    } else {
      return [
        { value: "Apprenti", label: "Apprenti" },
        { value: "ForPro Team", label: "ForPro Team" },
        { value: "Stagiaire", label: "Stagiaire" }
      ];
    }
  };

  return (
    <div className="form-content">
      <h2 className="section-title">Informations Personnelles</h2>

      <div className="form-row">
        <label className="label">Qui remplit*</label>
        <select
          {...register("quiRemplis", { required: "Ce champ est requis" })}
          className="input-field"
        >
          <option value="">Sélectionner...</option>
          {config.quiRemplisOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {errors.quiRemplis && (
        <p className="error-message">{errors.quiRemplis.message}</p>
      )}

      <div className="form-row">
        <label className="label">Nom*</label>
        <input
          {...register("nom", { required: "Nom est requis" })}
          type="text"
          className="input-field"
          placeholder="Entrez votre nom"
        />
      </div>
      {errors.nom && <p className="error-message">{errors.nom.message}</p>}

      <div className="form-row">
        <label className="label">Prénom*</label>
        <input
          {...register("prenom", { required: "Prénom est requis" })}
          type="text"
          className="input-field"
          placeholder="Entrez votre prénom"
        />
      </div>
      {errors.prenom && (
        <p className="error-message">{errors.prenom.message}</p>
      )}

      <div className="form-row">
        <label className="label">Compte Utilisateur</label>
        <input
          {...register("compteUtilisateur")}
          type="text"
          className="input-field"
          placeholder="Compte utilisateur (Modifiable)"
        />
      </div>

      <div className="form-row">
        <label className="label">Email</label>
        <input
          {...register("email")}
          type="email"
          className="input-field read-only"
          readOnly
          placeholder="Généré automatiquement"
        />
      </div>

      {/* Lab Selector */}
      <div className="form-row">
        <label className="label">Lab*</label>
        <select
          value={selectedLab}
          onChange={(e) => setSelectedLab(e.target.value)}
          className="input-field"
        >
          <option value="">Sélectionner un Lab...</option>
          {config.Labs.map((lab) => (
            <option key={lab} value={lab}>
              {lab}
            </option>
          ))}
        </select>
      </div>

      {/* Type Selector */}
      <div className="form-row">
        <label className="label">Type*</label>
        <select
          {...register("type", { required: "Type est requis" })}
          className="input-field"
        >
          <option value="">Sélectionner...</option>
          {getTypeOptions().map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {errors.type && <p className="error-message">{errors.type.message}</p>}

      {/* Display Function only for Non-Jeune Types */}
      {type !== "LearningLabJeune" && (
        <div className="form-row">
          <label className="label">
            Fonction*{" "}
            <span
              className="info-icon"
              data-tooltip="Fonction de la personne, par exemple aide comptable"
            >
              ℹ️
            </span>
          </label>
          <input
            {...register("fonction", { required: "Fonction est requise" })}
            type="text"
            className="input-field"
            placeholder="Entrez votre fonction"
          />
        </div>
      )}
      {errors.fonction && (
        <p className="error-message">{errors.fonction.message}</p>
      )}
      <div className="form-row">
        <label className="label">Date d’entrée en fonction*</label>
        <input
          {...register("dateEntree", { required: "Date d'entrée est requise" })}
          type="date"
          className="input-field"
          placeholder="Sélectionnez la date"
        />
      </div>
      {errors.dateEntree && (
        <p className="error-message">{errors.dateEntree.message}</p>
      )}
    </div>
  );
}

export default PersonalInfoSection;
